.cdc-panel {
  background-color: $white;
  border-radius: 20px;
  padding: 20px;
  @include shadow();
  margin-bottom: 50px;

  h3 {
    color: $dkorange;
    @include rem-size(1.5);

    span {
      display: inline-block;
      float:right;
      @include rem-size(1);
      position: relative;
      top: 6px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      i { @include rem-size(1.25);}
      &.green { color: green }
      &.red { color: red }
    }
  }
}

.ellipsis {
  max-width: 175px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alert.alert-custom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .fas {
    margin-right: 12px;
  }
  .alert-content {
    text-align: left;
    flex: 1;
  }

}
