.dashboard {
  padding: 20px;

  @media (max-width: 560px) {
    padding: 20px 0;
  }

  h3.mytitle {
    color: $green;
    border-bottom: solid 1px $white;
    padding-bottom: 5px;
    margin-bottom: 20px;
    @include rem-size(2.25);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn {
      position: relative;
      top: -3px;
      padding: 3px 20px;
    }

    @media (max-width: 600px) {
      padding: 0 20px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .btn {
        position: relative;
        top: 0px;
        padding: 3px 20px;
      }
    }
  }

  h3.action {
    color: $yellow;
    //border-bottom: solid 1px $white;
    //padding-bottom: 5px;
    margin-bottom: 20px;
    @include rem-size(1.5);
  }

  &-body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .delivery-list {
    width: 25%;
  }
  .delivery-editor {
    width: calc(75% - 20px);
    padding-left: 20px;
    border-left: solid 1px $white;
    min-height: 600px;
  }

  .btn.signout {
    float: right;
    position: relative;
    top: 3px;
    padding: 3px 20px;
  }

  .delivery {
    background-color: $beige;
    border-radius: 10px;
    margin-top: 10px;
    padding: 5px 10px;
    cursor: pointer;
  }

  label {
    color: $white;
  }

  .delivery-addresses {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;

      & > div {
        position: relative;
        overflow-y: visible;
        background-color: rgba(255,255,255,0.75);
        border-radius: 8px;
        border-color: #CCCCCC;
        padding: 10px;
        cursor: pointer;
        max-width: 32%;
        margin-right: 2%;

        &:last-child {
          margin-right: 0;
        }

        b { color: $red; }
        i { display: none; }

        &.active {
          border-color: $red;
          background-color: rgba(255,255,255,1);

          i {
            display: inline-block;
            position: absolute;
            top: -4px;
            right: -4px;
            color: $red;
            @include rem-size(1.7);
          }
        }
      }
  }

  .select-company-msg {
    margin-right: 14px;
    background-color: $white;
    border-radius: 8px;
    border-color: #CCCCCC;
    padding: 10px;
  }
}

h3.title {
  color: $green;
  padding-bottom: 5px;
  @include rem-size(2.25);
}

header {
  margin: 10px 0;
  border-bottom: solid 1px $gray20;
  margin-bottom: 20px;
}

.main-nav {
  ul {
    margin: 0;
    padding: 2px 0;
    list-style-type: none;
    background-color: $gray20;

    li {
      display: block;
      margin-bottom: 2px;

      a {
        display: block;
        text-decoration: none;
        @include rem-size(1.2);
        padding: 8px;
        background-color: #f6f6f6;

        &:hover {
          background-color: $red;
          color: $white;
        }
      }
    }
  }

}


h3.subtitle, h4.subtitle {
  button, a {
    float: right;
    margin-left: 14px;
  }
}

.form-wrapper {
  background-color: $gray10;
  padding: 30px 30px;
  border-radius: 10px;
}

.news-avatar {
  max-width: 24px;
  display: block;
  margin: auto;
}

td.no-wrap {
  white-space: nowrap;
}
