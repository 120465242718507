.member-table {
  margin: 40px 20px;
  padding: 40px;
  border-radius: 30px;
  background-color: $white;
  @include rem-size(1.1);

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 750px) {
      display: block;
      width: 100%;
    }
    @media (max-width: 475px) {
      zoom: 90%;
      margin: 0 8px;
    }

    h3 {
      display: inline-block;

      @media (max-width: 750px) {
        display: block;
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .new-member-btn {
      display: inline-block;
      .btn {
        padding-left: 4px;
      }
      @media (max-width: 750px) {
        display: block;
        width: 100%;
      }
    }

    .member-btn-group {
      display: inline-block;
      @media (max-width: 750px) {
        display: block;
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .btn {
      position: relative;
      top: -5px;
      font-weight: bold;
      padding: 0 14px;
      background-color: $gray-400;
      margin: 0 5px;
      color: $gray-600;
      @include rem-size(1.1);

      &.new {
        color: $white;
        background-color: $green;

        i {
          color: $white !important;
          position: relative;
          top: 1px;
          margin-right: 4px;
        }

        &:hover, &:active, &:focus {
          text-decoration: none;
          background-color: lighten($green,10%);
        }
      }

      &.active {
        color: $white;
        background-color: $red;

        &:hover, &:active, &:focus {
          text-decoration: none;
          background-color: lighten($red,10%);
        }
      }

      &:hover, &:active, &:focus {
        text-decoration: none;
        background-color: lighten($gray-400,10%);
      }
    }
  }

  .fad, .fas {
    color: $dkorange;
    cursor: pointer;
    margin-right: 10px;
    transform: scale(1);
    @include transition(all 0.3s ease);
    @include rem-size(1.25);

    &:hover {
      //color: darken($red, 10%);
      transform: scale(1.3);
    }
  }

  a {
    color: $text;
    &:hover {
      color: $dkorange;
    }
  }

  &.group-table {
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 1100px) {
  .hide-1100 { display: none; }
}

@media (max-width: 890px) {
  .hide-890 { display: none; }
  .member-table {
    padding: 30px 20px;
    margin: 40px 5px;
  }
}

@media (max-width: 730px) {
  .hide-730 { display: none; }
}

@media (max-width: 500px) {
  .hide-500 { display: none; }
  .member-panel {
    padding: 20px 0 !important;
    border-radius: 6px !important;
    h3 { margin: 0 12px 6px; }
  }
}

@media (max-width: 475px) {
  .hide-475 { display: none; }
  .member-table {
    padding: 30px 0;
    margin: 40px 0;
  }
}
