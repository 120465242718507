@import "php/bootstrap_overrides";
@import "fontawesome/fontawesome";
@import "bootstrap/bootstrap";
@import "mixins/mixins";
@import "php/login";
@import "php/wb-modal";
@import "php/dashboard";
@import "php/member-table";
@import "php/base";
@import "php/home";

html, body {
  font-size: $font-size-px-default;
}

body {

}

.app-wrapper {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #F6F6F6;
  //background-image: url(/assets/img/bg-cdc.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 200px;
}

label {
  font-weight: 700;
}

textarea, select, input, button { outline: none; }

.form-control:focus {
  border-color: #b989c7;
  outline: 0;
  box-shadow: none;
}

.input-group-prepend, .input-group-text {
  @include transition(all 0.15s ease);
}

.btn {
  text-align: left;
}

tr.expired {
  td, td a, td a:hover {
    color: #999;
  }
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgb(255, 244, 188);
}

.form-title {
  padding: 0;
  margin: 0 0 16px 0;
  color: $red;
}

.cc-wrap {
  zoom: 125%;
  border: solid 1px #ccc;
  border-radius: 8px;
  background-color: #FFF;
  padding-top: 7px !important;
}

iframe {
  pointer-events: none;
}


.react-datepicker-wrapper {
  display: block !important;
}

.btn {
  min-width: 30px;
  text-align: center;
}
.btn-disabled {
  opacity: 0.35 !important;
  cursor: not-allowed;
}

.hidden {
  display: none;
}

.truncate {
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scroll-vert {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc( 100vh - 163px );
}

.dir-image {
  margin-bottom: 20px;
  position: relative;

  & > .del-image {
    position: absolute;
    top: -6px;
    right: -6px;
    font-size: 24px;
    border-radius: 18px;
    overflow: visible;
    cursor: pointer;
    width: 20px;
    height: 22px;
    @include shadow(3px, 6px, 0.6);

    .fa-circle {
      color: #FFFFFF;
      position: absolute;
      font-size: 22px;
      top: 1px;
      right: 1px;
    }
    .fa-times-circle {
      color: $red;
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
    }
  }
}

.flex-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex-image {
  max-width: 30%;
  margin: auto;
  margin-bottom: 30px;

  img {
    max-width: 100%;
    border: solid 3px $gray-300;
    cursor: pointer;

    &:hover {
      border: solid 3px $red;
    }
  }
}

.form-group > label {
  display: block;

  & > span {
    color: $gray-600;
    font-weight: normal;
    display: inline-block;
    float: right;
  }
}
