.pf-login {
  text-align: center;
  padding-top: 40px;

  img {
    max-width: 250px;
    display: block;
    margin: 0 auto 10px;
  }

  .logo-title {
    @include rem-size(1.1);
    color: #518a44;
    font-weight: bold;
    padding-bottom: 40px;
    margin-bottom: 0;
    border-bottom: solid 1px #DDDDDD;
    b { color: $orange; }
  }

  &-panel {
    //min-height: 400px;
    max-width: 500px;
    padding: 30px 20px;
    margin: 0 auto;
    //background-color: rgba(255,255,255,0.8);
    //@include border-radius( 20px );
    //@include shadow(12px, 25px, 0.3);

    h1 {
      margin-bottom: 30px;
      color: $green;
      @include rem-size(1.7);
    }



    .form-group {
      text-align: left;
    }


    .input-group {
      zoom: 125%;
      border: solid 1px #ccc;
      border-radius: 8px;
      background-color: #FFF;
    }

    .input-group-prepend, .form-control, .input-group-text {
      border-radius: 8px;
    }

    .input-group-prepend.active .input-group-text {
      background-color: $orange;
      color: $white;
      border-color: $orange;
    }
  }

  .login-status {
    color: $yellow;
    font-weight: bold;
    @include rem-size(1.2);
    text-align: center;
    margin-top: 40px;
  }

  .more-actions {
    margin: 40px auto;
    max-width: 500px;

    h5 {
      margin-bottom: 20px;
    }
  }

}


.btn-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.btn-opt {
  border-bottom: solid 1px #DDDDDD;
  border-radius: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 !important;

  &:first-child {
    border-top: solid 1px #DDDDDD;
  }

  div i {
    margin-right: 5px;
    color: $orange;
  }
  .fa-arrow-right {
    color: #999999;
  }
}
