#homescreen {
  .choices {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .choice {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      color: $black;
      cursor: pointer;

      border-radius: 10px;
      background-color: $white;
      min-height: 100px;
      width: 100%;
      margin-bottom: 20px;
      padding: 15px;
      border: solid 2px #EEE;
      @include shadow();

      .description {
        text-align: left;
        padding-left: 20px;
        @include rem-size(1.2);
      }

      .fa-check-circle {
        color: $gray-400;
        @include rem-size(2.5);
      }

      &.selected {
        border-color: $orange;

        .fa-check-circle {
          color: $orange;
        }
      }
      &:hover {
        border-color: lighten($orange,20%);
        text-decoration: none;
      }
    }

  }

  .member-form {
    text-align: left;
    color: $black;
    cursor: pointer;
    //margin-top: 50px;
    //border-radius: 10px;
    //background-color: $white;
    min-height: 500px;
    width: 100%;
    margin-bottom: 20px;
    //padding: 15px;
    //border: solid 2px #EEE;
    @include rem-size(1.1);
    // @include shadow();

    h4 {
      margin-bottom: 20px;
    }

    h5 {
      margin-bottom: 16px;
      color: $orange;
    }

    .row {
      .col-6:first-child {
        padding-right: 5px;
      }
      .col-6:last-child {
        padding-left: 5px;
      }

      .col-4:first-child {
        padding-right: 5px;
      }
      .col-4:last-child {
        padding-left: 5px;
      }
      .col-4:nth-child(2) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .more-member {
    border-left: solid 3px $orange;
    padding-left: 10px;
  }

  .form-control {
    border: solid 1px $gray-500;
  }

  .check-choices {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .check-choice {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 16px;
    border: solid 2px #EEE;
    padding: 8px 12px;
    border-radius: 8px;

    &.selected {
      border-color: $orange;
      background-color: $white;
    }

    i {
      position: relative;
      top: 5px;
      margin-right: 8px;

      &.fas {
        color: $orange;
      }
      &.far {
        color: $gray-500;
      }
    }
  }

}


.container-2 {
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1160px) {
    margin: 0 20px;
  }

}
