$font-size-px-default: 14px;

$red: #be0000;
$yellow: #ECA62C;
$orange: #e7960c;
$dkorange: #ec7a17;
$blue: #70bbfd;
$green: #007c14;
$beige: #F2EEE9;
$text: #1C1610;
$shadow: #9f9b96;

$white:  #FFFFFF;
$gray05: #f0f0f0;
$gray10: #ececed;
$gray15: #ddddde;
$gray20: #d8d8da;
$gray30: #c4c4c8;
$gray40: #b0b0b5;
$gray50: #9d9da3;
$gray60: #898990;
$gray70: #75757e;
$gray80: #61616b;
$gray90: #4e4e59;
$black:  #3a3a46;
$black000: #000000;

$colors: (
        "purple": #7D438E,
        "orange": $orange,
        "red": $red,
        "green": $green,
        "yellow": $yellow
);
$primary: $red;

$font-family-sans-serif: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$btn-font-weight:       700;
$btn-border-radius:     12px;
$btn-border-radius-lg:  18px;
$btn-border-radius-sm:  8px;

$headings-font-weight:  900 !default;

$label-margin-bottom:      5px;
$input-border-radius:      4px;
$input-border-radius-lg:   18px;
$input-border-radius-sm:   8px;

$input-group-addon-bg: #FFFFFF;
$input-group-addon-border-color: #FFFFFF;
$input-border-color: #FFFFFF;
