.wb-modal, .pay-form {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  overflow: scroll;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease, z-index 1ms ease 1s;
  width: 100vw;
  height: 100vh;

  form {
    padding: 0 20px;
  }

  label {
    color: $text !important;
    @include rem-size(1);
  }

  &-blur {
    filter: blur(6px);
  }

  &-wrap {
    display: table-cell;
    vertical-align: middle;
    width: 100vw;
    height: 100vh;
  }

  &-body {
    min-height: 300px;
    width: calc(100vw - 100px);
    max-width: 1500px;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    background-color: #F6F6F6;
    position: relative;
    padding: 15px 20px 30px;
    max-height: calc(100vh - 80px);
    overflow: visible;
    @include shadow(10px, 20px, 0.4);
    @include transition(all 0.3s ease);

    @media (max-width: 500px) {
      margin-top: 20px;
      margin-bottom: 20px;
      max-height: calc(100vh - 40px);
      width: calc(100vw - 40px);
    }

    h3 {
      color: $red;
      padding-bottom: 8px;
      border-bottom: solid 1px $gray-600;
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 20px;
    }

    .form-control {
      border-color: $gray-500;
    }

    .form-group-address {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;

      .form-group {
        width: 32%;
      }
    }
  }

  &-footer {
    padding-top: 20px;
    border-top: solid 1px $gray-600;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    button {
      padding: 5px 25px;
    }

  }

  &-pdf, &-pdf iframe {
    height: calc(100vh - 80px);
    width: calc(100vw - 400px);
    max-width: 1500px;
  }

  &-scroller {
    height: calc(100vh - 80px);
    overflow-y: auto;
  }

  &.large, &.xl {
    .wb-modal-body {
      width: 90%;
      max-width: 1000px;
      min-height: 500px;
    }
  }

  &.small {
    .wb-modal-body {
      max-width: 650px;
      min-height: 300px;
    }
  }

  &.open {
    opacity: 1;
    transition: z-index 1ms, opacity 0.3s ease 1ms;
    z-index: 1001;

  }


  &-content {
    padding: 0;
    @include rem-size(1);
    overflow: hidden;
    height: calc( 100vh - 163px );

    & > .container {
      height: calc( 100vh - 163px );
    }
    & > .container > .row {
      height: calc( 100vh - 163px );
    }
  }
  &-close {
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 36px;
    border-radius: 18px;
    overflow: visible;
    cursor: pointer;
    width: 35px;
    height: 32px;
    @include shadow(3px, 6px, 0.6);

    .fa-circle {
      color: #FFFFFF;
      position: absolute;
      font-size: 34px;
      top: 1px;
      right: 1px;
    }
    .fa-times-circle {
      color: $red;
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
    }
  }
  &-save {
    position: absolute;
    width: 100%;
    display: block;
    bottom: -13px;
    font-size: 36px;
    overflow: visible;
    cursor: pointer;
    height: 32px;
    text-align: center;

    button {
      min-width: 200px;
      border-radius: 50px;
      font-weight: bold;
      @include shadow(3px, 6px, 0.6);
      @include rem-size(1.2);
    }
  }


}

.modal-strengths-needs {
  margin-bottom: 40px;
}

.rpv-core-inner-page {
  padding: 0 !important;
}
.rpv-core-page-layer {
  // box-shadow: none;
}

.wb-modal {
  .input-group-text {
    background-color: #adb5bd;
    border-color: #adb5bd;
    border-radius: 4px 0 0 4px;
    border: 0;
    color: #000;
    font-weight: 900;
  }
}


.pay-form {
  width: 100%;
  display: table;
  position: initial;
  background-color: #FFF;
  overflow: initial;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s ease, z-index 1ms ease 1s;

  h3 {
    color: $red;
    padding-bottom: 8px;
    border-bottom: solid 1px #DDD;
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 20px;
  }

  .form-control {
    border-color: $gray-500;
  }

  .input-group-text {
    background-color: #DDD;
    border: 1px solid $gray-500;
    border-right: 0;
    border-radius: 4px 0 0 4px;
  }

  .form-group-address {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    .form-group {
      width: 32%;
    }
  }

  .wb-modal-footer {
    text-align: center;
    padding: 0 20px;
    border-top: 0;
    margin-top: 20px;
    display: block;
  }

}
